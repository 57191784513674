import './App.scss';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NewHeader from "./components/header/newHeader/newHeader";
import Footer from "./components/footer/footer";
import Homepage from "./containers/homepage/homepage";
import ContactUs from "./containers/contactUs/contactUs";
import SingleProjectPage from "./containers/singlePages/singleProjectPage"
import OurProjects from "./containers/ourProjects/ourProjects";
import Porosia from "./containers/porositTani/porosia";
import CommingSoon from "./components/commingSoon/comming-soon";
import AboutUs from './containers/aboutUs/aboutUs';
import Management from './containers/management/management';
import OurProducts from "./containers/ourProducts/ourProducts"
import SingleProduct from './containers/singleProduct/singleProduct';
import Mirenjohjet from './containers/mirenjohjet/mirenjohjet';
import Certifikatat from './containers/certifikatat/certifikatat';
import Katallogjet from './containers/katallogjet/katallogjet';
import BackToTop from "./components/backToTop/backToTop";
import ForPublic from './containers/forPublic/forPublic';
import B2b from './containers/b2b/b2b';
import PergjegjsiaSociale from './containers/pergjegjsiaSociale/pergjegjsiaSociale';
import StandardiXhamit from './containers/standardiXhamit/standardiXhamit';
import { useEffect } from 'react';
import axios from './axios';
import { setLangData } from './redux/Functions/actions';
import { connect } from 'react-redux';
import OurStaff from './containers/ourStaff/ourStaff';
import News from './containers/news/news';
import SingleNews from './containers/news/single/singleNews';
import NewHomepage from './containers/homepage/newHomepage';


function App(props) {

  // GET ALL LABELS HERE
  const getAllLabels = () => {
    axios.get("/api/client/homepage/all-labels")
      .then((res) => {
        props?.setLangData(res.data);
      })
      .catch((err) => {
        console.error('res homepage sliders', err)
      })
  }
  useEffect(() => {
    getAllLabels()
  }, [])
  return (
    <div className="App ">
      <ToastContainer position="bottom-left" theme="colored" style={{ zIndex: '999999' }} />
      {/* <BackToTop /> */}
      <Router>
        {/* {window.location.pathname !== "/" ? <NewHeader /> : ""} */}
        {<NewHeader />}
        {/* <NewHeader /> */}
        <Routes>
          <Route exact path="/coming_soon" element={<CommingSoon />} />
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/newHomepage" element={<NewHomepage />} />
          <Route exact path="/aboutUs" element={<AboutUs />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/projects" element={<OurProjects />} />
          <Route exact path="/project/:id/:type" element={<SingleProjectPage />} />
          <Route exact path="/management" element={<Management />} />
          {/* <Route exact path="/our-staff" element={<OurStaff />} /> */}
          <Route exact path="/products" element={<OurProducts />} />
          <Route exact path="/mirenjohjet" element={<Mirenjohjet />} />
          <Route exact path="/certifikatat" element={<Certifikatat />} />
          <Route exact path="/katallogjet" element={<Katallogjet />} />
          <Route exact path="/forPublic" element={<ForPublic />} />
          <Route exact path="/B2B" element={<B2b />} />
          <Route exact path="/socialResponsebility" element={<PergjegjsiaSociale />} />
          <Route exact path="/standardiXhamit" element={<StandardiXhamit />} />
          <Route exact path="/products/:type" element={<SingleProduct />} />
          <Route exact path="/order" element={<Porosia />} />
          <Route exact path="/news" element={<News />} />
          <Route exact path="/news/:id" element={<SingleNews />} />
        </Routes>
        <Footer />
        {/* {window.location.pathname !== "/" ? <Footer /> : ""} */}

      </Router>

    </div>
  );
}
const mapStateToProps = state => {
  return {
      selected_lang: state.data.selected_lang,
      langData: state.data.langData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      setLangData: (labels) => dispatch(setLangData(labels)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default App;
